// HeroSection.js

import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


function HeroSection() {
    return (
        <Box 
            sx={{ 
                position: 'relative', 
                width: '100%', 
                height: '500px', 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', 
                color: 'black',
                paddingBottom:'40px',
                paddingTop:'10px',
                '@media (max-width:600px)': {
                    flexDirection: 'column', // Stack content vertically on small screens
                    height: 'auto',
                    paddingBottom: '20px',
                    paddingTop: '20px',
                    textAlign: 'center', // Center text for better mobile experience
                
        },
            }}
        >
        
            <Container maxWidth="md" sx={{ textAlign: 'left', marginLeft: "0px", }}>
            <Typography variant="h2" fontWeight="bold" gutterBottom>
                    Achieve Your Canadian Education Goals
                </Typography>
                <Typography variant="h4" paragraph sx={{maxWidth: "550px"}}>
                    Expert support for student visas, admissions, and everything you need for success.
                </Typography>

                <Button 
                    variant="contained" 
                    color="primary" 
                    size="large" 
                    sx={{ marginTop: 2 }} 
                    component={RouterLink} 
                    to="/contact" // All buttons will navigate to /contact
                >
                    Book Free Assessment
                </Button>



                <Box 
                    component="img" 
                    src="/canadianFlag.png" 
                    alt="Canadian Flag" 
                    sx={{ 
                        position: 'absolute', 
                        top: 10, 
                        right: 0, 
                        height: 'auto', 
                        maxHeight: '90%', 
                        width: 'auto', 
                        maxWidth: '50%', 
                        display: { xs: 'none', md: 'block' } // Hide on small screens
                    }} 
                />
                <Box 
                    component="img" 
                    src="/bannerWoman.png" 
                    alt="Woman" 
                    sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        right: 250, 
                        height: 'auto', 
                        maxHeight: '90%', 
                        width: 'auto', 
                        maxWidth: '50%', 
                        display: { xs: 'none', md: 'block' } // Hide on small screens
                    }} 
                />
            </Container>
        </Box>
    );
}

export default HeroSection;
