const keyEssentials = [
    {
        title: 'Student Loans',
        details : 'We help you secure the best loan options for your studies abroad.',
        image: '/Rectangle25.png',
        icon: '/31464651.png',
        slug: 'student-loans',
        description: '/markdown/student-loans.md',
    },
    {
        title: 'Money Transfer',
        details : 'Get access to fast and reliable international money transfer services.',
        image: '/Rectangle40.png',
        icon: '/10419081.png',
        slug: 'money-transfer',
        description: '/markdown/money-transfer.md',
    },
    {
        title: 'Student Banking',
        details : 'Open an international student bank account with ease.',
        image: '/Rectangle41.png',
        icon: '/29764101.png',
        slug: 'student-banking',
        description: '/markdown/student-banking.md',
    },
    {
        title: 'Find Accommodation',
        details : 'We help you find the perfect accommodation near your university.',
        image: '/Rectangle43.png',
        icon: '/38502031.png',
        slug: 'find-accommodation',
        description: '/markdown/find-accommodation.md',
    },
];

export default keyEssentials;
