// Footer.js

import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Link, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
    // State to control each modal's open/close status
    const [openTerms, setOpenTerms] = useState(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openCookie, setOpenCookie] = useState(false);

    // Handlers to open and close each modal
    const handleOpenTerms = () => setOpenTerms(true);
    const handleCloseTerms = () => setOpenTerms(false);

    const handleOpenPrivacy = () => setOpenPrivacy(true);
    const handleClosePrivacy = () => setOpenPrivacy(false);

    const handleOpenCookie = () => setOpenCookie(true);
    const handleCloseCookie = () => setOpenCookie(false);

    return (
        <Box sx={{ backgroundColor: '#2e2e2e', color: 'white', paddingTop: 4, paddingBottom: 4 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4} justifyContent="space-between">
                    {/* Company Information Section */}
                    <Grid item xs={12} md={4}>
                        <Grid item>
                            <img src='/logo.png' alt='Company Logo' width='auto' height='50' />
                        </Grid>

                        <Typography variant="body2" color="gray" paragraph>
                            Your trusted partner for student immigration services, helping you achieve your dreams abroad.
                        </Typography>
                        <Typography variant="body2" color="gray">
                            © {new Date().getFullYear()} Perfect Group Consulting . All rights reserved.
                        </Typography>
                    </Grid>

                    {/* Quick Links Section */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            Quick Links
                        </Typography>
                        <Link href="/" color="inherit" underline="hover" display="block" mb={1}>
                            Home
                        </Link>
                        <Link href="/contact" color="inherit" underline="hover" display="block" mb={1}>
                            Apply Now
                        </Link>
                        <Link href="/study-abroad#faq" color="inherit" underline="hover" display="block" mb={1}>
                            FAQs
                        </Link>
                        <Link href="/contact" color="inherit" underline="hover" display="block">
                            Contact
                        </Link>
                    </Grid>

                    {/* Legal Section */}
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            Legal
                        </Typography>
                        <Link onClick={handleOpenTerms} color="inherit" underline="hover" display="block" mb={1} sx={{ cursor: 'pointer' }}>
                            Terms & Conditions
                        </Link>
                        <Link onClick={handleOpenPrivacy} color="inherit" underline="hover" display="block" mb={1} sx={{ cursor: 'pointer' }}>
                            Privacy Policy
                        </Link>
                        <Link onClick={handleOpenCookie} color="inherit" underline="hover" display="block" sx={{ cursor: 'pointer' }}>
                            Cookie Settings
                        </Link>
                    </Grid>

                    {/* Social Media Section */}
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            Follow Us
                        </Typography>
                        <Link href="#" color="inherit" underline="hover" display="block" mb={1}>
                            Facebook
                        </Link>
                        <Link href="#" color="inherit" underline="hover" display="block" mb={1}>
                            Twitter
                        </Link>
                        <Link href="#" color="inherit" underline="hover" display="block">
                            LinkedIn
                        </Link>
                    </Grid>
                </Grid>

                <Divider sx={{ marginY: 3, backgroundColor: 'gray' }} />

                <Typography variant="body2" align="center" color="gray">
                    Designed by Perfect Group Consultants | Developed by <a href="https://mujtabachaudhry.ca" style={{ color: 'white' }}>Mujtaba Chaudhry</a>
                </Typography>
            </Container>

        {/* Terms & Conditions Modal */}
<Dialog open={openTerms} onClose={handleCloseTerms} fullWidth maxWidth="sm">
    <DialogTitle>Terms & Conditions</DialogTitle>
    <DialogContent>
        <Typography variant="body2">
            By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.
            <br /><br />
            1. Use of Services: Our services are intended for lawful purposes only. You agree not to misuse our services or help others do so.
            <br /><br />
            2. User Responsibilities: You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.
            <br /><br />
            3. Limitation of Liability: We will not be liable for any indirect, incidental, special, or consequential damages resulting from your use or inability to use our services.
            <br /><br />
            4. Changes to Terms: We reserve the right to modify these terms at any time. We will provide notice of changes via our website.
            <br /><br />
            5. Contact Us: For any questions about these terms, please contact us at support@perfectgroup.ca.
        </Typography>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseTerms} color="primary">Close</Button>
    </DialogActions>
</Dialog>

{/* Privacy Policy Modal */}
<Dialog open={openPrivacy} onClose={handleClosePrivacy} fullWidth maxWidth="sm">
    <DialogTitle>Privacy Policy</DialogTitle>
    <DialogContent>
        <Typography variant="body2">
            Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information.
            <br /><br />
            1. Information Collection: We may collect personal information such as your name, email address, and contact details when you interact with our services.
            <br /><br />
            2. Use of Information: We use your information to provide and improve our services, respond to inquiries, and for marketing purposes with your consent.
            <br /><br />
            3. Data Protection: We employ security measures to protect your personal information. However, no method of electronic storage is 100% secure.
            <br /><br />
            4. Third-Party Disclosure: We do not sell or trade your personal information to outside parties without your consent, except as required by law.
            <br /><br />
            5. Contact Us: For questions regarding our Privacy Policy, please contact us at privacy@perfectgroup.ca.
        </Typography>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClosePrivacy} color="primary">Close</Button>
    </DialogActions>
</Dialog>

{/* Cookie Settings Modal */}
<Dialog open={openCookie} onClose={handleCloseCookie} fullWidth maxWidth="sm">
    <DialogTitle>Cookie Settings</DialogTitle>
    <DialogContent>
        <Typography variant="body2">
            We use cookies to enhance your experience on our website. This section explains how we use cookies and how you can manage your cookie preferences.
            <br /><br />
            1. What Are Cookies?: Cookies are small files stored on your device that help us analyze web traffic or track your preferences.
            <br /><br />
            2. Types of Cookies We Use:
                - Essential Cookies: Necessary for website functionality.
                - Analytical Cookies: Help us understand how you interact with our website.
                - Marketing Cookies: Used to display relevant ads.
            <br /><br />
            3. Managing Cookies: You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser settings to decline cookies if preferred.
            <br /><br />
            4. Contact Us: For questions or concerns about our Cookie Policy, please contact us at support@perfectgroup.ca.
        </Typography>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseCookie} color="primary">Close</Button>
    </DialogActions>
</Dialog>

        </Box>
    );
}

export default Footer;
