// StepByStepProcessSection.js

import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Button,
    Box,
    useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink

const steps = [
    {
        title: "Submit Initial Application",
        description: "Fill out the application form and submit your details.",
        icon: <AssignmentIcon fontSize="large" color="primary" />,
    },
    {
        title: "Check Eligibility & Submit Documents",
        description: "Ensure you meet the requirements and provide necessary documents.",
        icon: <DocumentScannerIcon fontSize="large" color="primary" />,
    },
    {
        title: "Visa Processing & Approval",
        description: "We assist you through the visa processing stage.",
        icon: <HowToRegIcon fontSize="large" color="primary" />,
    },
    {
        title: "Pre-Departure Briefing",
        description: "Get ready for your journey with our pre-departure briefing.",
        icon: <FlightTakeoffIcon fontSize="large" color="primary" />,
    },
];

// Styled Components
const AnimatedBox = styled(motion.div)({
    width: '100%',
    height: '100%',
});

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const StyledCard = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '15px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)',
    position: 'relative',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.2)',
    },
}));

const ConnectorLine = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '4px',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    transform: 'translate(-50%, -50%)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '4px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

function StepByStepProcessSection() {
    const theme = useTheme();

    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.2, duration: 0.6, type: 'spring', stiffness: 50 },
        }),
    };

    return (
        <Container sx={{ marginTop: '50px', marginBottom: '50px' }}>
            <Typography variant="h3" align="center" gutterBottom color="textPrimary" fontWeight="bold">
                How It Works
            </Typography>
            <Box
                sx={{
                    width: '80px',
                    height: '4px',
                    backgroundColor: theme.palette.primary.main,
                    margin: '0 auto 40px auto',
                    borderRadius: '2px',
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                {/* Connector Line */}
               

                {/* Steps Container */}
                <Grid container spacing={4} sx={{ position: 'relative', zIndex: 1 }}>
                    {steps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <AnimatedBox
                                custom={index}
                                variants={cardVariants}
                                initial="hidden"
                                animate="visible"
                                whileHover={{ scale: 1.05 }}
                            >
                                <StyledCard>
                                    {step.icon}
                                    <Typography variant="h6" gutterBottom mt={2} fontWeight="600">
                                        {step.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {step.description}
                                    </Typography>
                                    <StyledButton 
                                        variant="contained" 
                                        size="small" 
                                        component={RouterLink} // Wrap the button with RouterLink
                                        to="/contact#form" // All buttons will navigate to /contact
                                    >
                                        Learn More
                                    </StyledButton>
                                </StyledCard>
                            </AnimatedBox>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
}

export default StepByStepProcessSection;
