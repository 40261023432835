// WhyGlobalCounselPage.js

import React, {useState} from 'react'; 
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Container,
    CardActions,
    Button,
    Box,
    Avatar,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, 
} from '@mui/material';
import { styled } from '@mui/system';
import GavelIcon from '@mui/icons-material/Gavel';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Added Email Icon
import PhoneIcon from '@mui/icons-material/Phone'; // Added Phone Icon
import { Link as RouterLink } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { WhatsApp } from '@mui/icons-material';
// Removed GitHubIcon since we're no longer using GitHub 
import { light } from '@mui/material/styles/createPalette';
import { Divider } from '@mui/material';
import { Helmet } from 'react-helmet';
import {Tooltip} from '@mui/material';




// Updated team members data
const teamMembers = [
    {
        name: 'Waqas Chaudhry',
        role: 'CEO',
        description: 'Experienced leader with a vision for global expansion and innovation.',
        avatar: '/waqas.jpg', // Replace with actual image path
        contact: {
            email: 'mon2waqas@gmail.com', // Added Email
            phone: '4168772773', // Added Phone
        }
    },
    {
        name: 'Faraz Chaudhry',
        role: 'Pakistan Consultant',
        description: 'Specialized in navigating Pakistan’s market and providing expert insights.',
        avatar: '/faraz.jpg', // Replace with actual image path
        contact: {
            email: 'faraz@example.com', // Added Email
            phone: '923214798616', // Added Phone
        }
    },
    {
        name: 'Shahzada Ruhaan',
        role: 'Managing Director',
        description: 'Dedicated to providing exceptional services and support to our clients.',
        avatar: '/shahzada.jpg', // Replace with actual image path
        contact: {
            email: 'sarah@example.com', // Added Email
            phone: '16479840159', // Added Phone
        }
    },
];
const whyGlobalCounselItems = [
    {
        title: 'Legal Expertise',
        description: 'Expert guidance on immigration laws and regulations to ensure compliance and success.',
        icon: 'Gavel',
        details: 'Our legal team specializes in Canadian immigration laws, specifically for students from Pakistan. We provide in-depth guidance to ensure your applications meet every requirement. By staying up-to-date with immigration policies and regulation changes, we help minimize risk and maximize your chances of success.',
    },
    {
        title: 'Student-Focused Consulting',
        description: 'Tailored consulting services to guide students through every step of studying abroad.',
        icon: 'BusinessCenter',
        details: 'Our consulting services are crafted specifically for students. We assist you in choosing the right educational path, planning your journey, and navigating everything from program selection to accommodation. We provide comprehensive support that goes beyond just visa applications, ensuring your transition to studying abroad is smooth and well-prepared.',
    },
    {
        title: 'Multilingual Support',
        description: 'Assistance in multiple languages to cater to diverse client needs.',
        icon: 'Language',
        details: 'Our team offers multilingual support, including English and Urdu, ensuring that language is never a barrier. This allows us to explain complex procedures clearly and provide a personalized experience for our clients in Pakistan and other regions.',
    },
    {
        title: 'Security & Compliance',
        description: 'Ensuring your immigration processes meet all legal and regulatory standards.',
        icon: 'Security',
        details: 'We ensure that your application and documentation comply with all Canadian immigration regulations. Our team thoroughly checks every step for compliance to safeguard you against potential issues, giving you peace of mind throughout the process.',
    },
    {
        title: '24/7 Support',
        description: 'Continuous support to address your immigration queries and concerns at any time.',
        icon: 'SupportAgent',
        details: 'We recognize the importance of having reliable support when applying for studies abroad. Our dedicated team is available 24/7 to assist with any questions or concerns, ensuring that you have guidance whenever you need it, especially during critical application phases.',
    },
];

// Mapping icon names to actual icon components
const iconMap = {
    Gavel: <GavelIcon fontSize="large" />,
    BusinessCenter: <BusinessCenterIcon fontSize="large" />,
    Language: <LanguageIcon fontSize="large" />,
    Security: <SecurityIcon fontSize="large" />,
    SupportAgent: <SupportAgentIcon fontSize="large" />,
};

// Styled components for custom styling
const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    borderRadius: '12px',
    boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    '&:hover': {
        transform: 'translateY(-10px) rotate(1deg)',
        boxShadow: '0 12px 24px rgba(0,0,0,0.2)',
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
}));

const StyledCard1 = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    padding: theme.spacing(3),
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    backgroundColor: theme.palette.background.paper, // Optional: Adjust background color
    '&:hover': {
        transform: 'translateY(-5px)',
    },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '16px',
        padding: theme.spacing(2),
        background: 'linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%)',
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: '700',

    color: theme.palette.primary.main,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: theme.spacing(2, 3),

}));

const CloseButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));
const RoleTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: '500',
}));

const SocialIcons = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
}));



function WhyPerfectGroupPage() {
    const [openModal, setOpenModal] = useState(null);
    window.scrollTo(0, 0);

    const handleOpenModal = (index) => {
        setOpenModal(index);
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    return (
        <>
     <Helmet>
    <title>Why Choose Perfect Group Consultants</title>
    <meta name="description" content="Learn why Perfect Group Consultants is your best partner for immigration and student visa success. We offer expert guidance and 24/7 support." />
    <meta property="og:title" content="Why Choose Perfect Group Consultants" />
    <meta property="og:description" content="Perfect Group Consultants provides expert immigration guidance, business solutions, and support for students studying abroad." />
    <meta property="og:type" content="website" />
    <link rel="canonical" href="https://perfectgroup.ca/why-us" />
</Helmet>

<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://perfectgroup.ca/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Why Us",
                "item": "https://perfectgroup.ca/why-us"
            }
        ]
    }
`}
</script>

<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Perfect Group Consultants",
        "description": "Providing immigration support, business consulting, and guidance for students studying abroad.",
        "url": "https://perfectgroup.ca"
    }
`}
</script>

            {/* Hero Section */}
            <Box 
    sx={{ 
        height: '400px', 
        backgroundImage: 'url(/banner-image3.png)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',  // Center vertically
        justifyContent: 'left',  // Center horizontally
        color: 'black',
        textAlign: 'left',
        '@media (max-width:600px)': {
            backgroundImage: 'none', 
            justifyContent: 'center',
            alignItems: 'center', 
            paddingLeft: "0px"
        },
    }}
>
    <Box 
        sx={{ 
            display: 'flex',  // Add this line
            flexDirection: 'column',  // Ensures items stack vertically
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            justifyContent: 'center',
            alignItems: 'left',
            padding: '20px', 
            borderRadius: '8px',
            maxWidth: '720px',
            height: "100%"
        }}
    >
<Typography variant="h2" fontWeight="bold">
    Why Choose Perfect Group Consultants
</Typography>
<Typography variant="h5">
    Your Reliable Partner for Student Visas and University Placement
</Typography>

    </Box>
</Box>

            <Divider 
                sx={{ 
                    display: { xs: 'block', sm: 'none' }, // Step 3: Responsive display
                    width: '90%', // Optional: Adjust width
                    margin: '20px auto', // Optional: Center and add vertical margin
                    borderColor: 'primary.main', // Optional: Set color
                }} 
            />
            {/* Team Section */}
            <Container sx={{ my: 8 }}>
                <Typography 
                    variant="h4" 
                    component="h2" 
                    fontWeight="700" 
                    align="center" 
                    gutterBottom
                    sx={{
                        fontFamily: 'Roboto, sans-serif',
                        color: 'primary.main',
                        fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' },
                        marginBottom: { xs: 2, sm: 3 },
                    }}
                > 
                    Meet Our Team
                </Typography>
                
                {/* Section Subtitle/Description */}
                <Typography  
                    variant="subtitle1"  
                    align="center" 
                    paragraph
                    sx={{
                        fontFamily: 'Roboto, sans-serif',
                        color: 'text.secondary',
                        maxWidth: '900px',
                        margin: '0 auto',
                        lineHeight: 1.6,
                        fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
                        paddingX: { xs: 2, sm: 4 },
                    }}
                >
                    We have been dedicated to providing exceptional services for many years, continually striving for excellence and innovation. Our experienced team brings a wealth of knowledge and passion to every project, ensuring that our clients receive the best support and guidance possible.
                </Typography>

                {/* Decorative Divider */}
                <Box 
                    sx={{ 
                        width: '80px', 
                        height: '3px', 
                        backgroundColor: 'primary.main', 
                        margin: '40px auto', 
                        borderRadius: '2px' 
                    }} 
                />
                
                <Grid container spacing={4} justifyContent="center">
                    {teamMembers.map((member, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <StyledCard1>
                                <Avatar
                                    src={member.avatar}
                                    alt={`${member.name} - ${member.role}`}
                                    sx={{ width: 80, height: 80, margin: '0 auto', mb: 2 }}
                                />
                                <Typography variant="h6" fontWeight="bold">{member.name}</Typography>
                                <RoleTypography variant="subtitle2">{member.role}</RoleTypography>
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                    {member.description}
                                </Typography>
                                <SocialIcons>
                                    {member.contact.email && (
                                        <IconButton
                                            href={`mailto:${member.contact.email}`}
                                            aria-label="Email"
                                            color="primary"
                                        >
                                            <MailOutlineIcon />
                                        </IconButton>
                                    )}
                                    {member.contact.phone && (
                                         <Tooltip title="You will be redirected to WhatsApp" arrow>
                                       <IconButton
                                       href={`https://wa.me/${member.contact.phone}`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       aria-label="Chat on WhatsApp"
                                       color="primary"
                                   >
                                       <WhatsApp />
                                   </IconButton>
                                   </Tooltip>
                                   
                                    )}
                                 
                                </SocialIcons>
                            </StyledCard1>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Why Perfect Group Consultants Section with Light Grey Background */}
            <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
                <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" fontWeight="bold" paragraph>
                        Study Abroad With Someone Who Knows The Way
                    </Typography>
                    <Box 
                        sx={{ width: '80px', height: '3px', backgroundColor: 'red', margin: '0 auto', marginBottom:5 }} 
                    />

                    {/* Why Perfect Group Consultants Items */}
                    <Grid container spacing={4} justifyContent="center">
                        {whyGlobalCounselItems.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <StyledCard elevation={0}>
                                    <CardContent sx={{ padding: '24px' }}>
                                        <IconWrapper>
                                            {iconMap[item.icon] || <GavelIcon fontSize="large" />}
                                        </IconWrapper>
                                        <Typography variant="h5" fontWeight="700" gutterBottom align="center">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" align="center">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            size="small" 
                                            onClick={() => handleOpenModal(index)}
                                           
                                        >
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>

                    <Box sx={{ display: 'flex', gap: 2, mt: 4, justifyContent: 'center', alignContent: 'center' }}>
                        <Button component={RouterLink} to="/study-abroad" variant="outlined">
                            Study Abroad
                        </Button>
                        <Button component={RouterLink} to="/student-essentials" variant="outlined">
                            Student Essentials
                        </Button>
                        <Button component={RouterLink} to="/contact" variant="outlined">
                            Contact Us
                        </Button>
                    </Box>

                </Container>
            </Box>

            {whyGlobalCounselItems.map((item, index) => (
                <StyledDialog
                    key={index}
                    open={openModal === index}
                    onClose={handleCloseModal}
                    fullWidth
                    maxWidth="sm"
                >
                    <StyledDialogTitle>{item.title}</StyledDialogTitle>
                    <StyledDialogContent>
                        <Typography variant="body1" color="text.primary">
                            {item.details}
                        </Typography>
                    </StyledDialogContent>
                    <StyledDialogActions>
                        <CloseButton onClick={handleCloseModal} variant="contained">
                            Close
                        </CloseButton>
                    </StyledDialogActions>
                </StyledDialog>
            ))}

            
        </>
    );
}


export default WhyPerfectGroupPage
