// AssessmentSection.js

import React from 'react';
import { Container, Typography, Grid, TextField, Button, Box, FormControlLabel, Checkbox } from '@mui/material';
import emailjs from '@emailjs/browser'; // Import EmailJS

function AssessmentSection() {
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission
        const formData = new FormData(event.target); // Get form data
        const data = Object.fromEntries(formData); // Convert to object

        // Send email using EmailJS
        emailjs.send('service_z7v2kgp', 'template_zhergm4', data, 'oiFp-rJWke5iUCQaE')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    return (
        <Container maxWidth="md" sx={{ marginBottom:'100px', padding:10 }}>
            <form onSubmit={handleSubmit}> {/* Add form submission handler */}
                <Grid container spacing={3}>
                    {/* Image Section */}
                    <Grid item xs={12} sm={6}>
                        <img src="/student.png" style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="Student" />
                    </Grid>
                    
                    {/* Text Section */}
                    <Grid item xs={12} sm={6}>
                        <Box textAlign="left" mb={5}>
                            <Typography variant="h4" fontWeight="bold" gutterBottom>
                                Study Abroad Free Assessment
                            </Typography>
                        
                            <Typography variant="subtitle1" color="text.secondary">
                                Fill in your details below to get personalized advice.
                            </Typography>
                        </Box>

                        {/* Form Section */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="First Name" 
                                    fullWidth 
                                    required 
                                    variant="outlined"
                                    name="first_name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="Last Name" 
                                    fullWidth 
                                    required 
                                    variant="outlined"
                                    name="last_name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="Email Address" 
                                    fullWidth 
                                    required 
                                    variant="outlined" 
                                    type="email"
                                    name="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="Mobile Number" 
                                    fullWidth 
                                    required 
                                    variant="outlined" 
                                    type="tel"
                                    name="mobile"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="Country of Destination" 
                                    fullWidth 
                                    required 
                                    variant="outlined"
                                    name="country"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField 
                                    label="Preferred Course of Study" 
                                    fullWidth 
                                    required 
                                    variant="outlined"
                                    name="course"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Do you need counseling?" 
                                    fullWidth 
                                    multiline 
                                    rows={3} 
                                    variant="outlined"
                                    name="counseling"
                                />
                            </Grid>
                        </Grid>

                        {/* Terms and Conditions */}
                        <Box mt={3} mb={2} display="flex" alignItems="center">
                            <FormControlLabel
                                control={<Checkbox required />}
                                label={
                                    <Typography variant="body2" color="text.secondary">
                                        By filling out your details, you agree to share your information with our team for consultation purposes.
                                    </Typography>
                                }
                            />
                        </Box>

                        {/* Submit Button */}
                        <Box textAlign="center" mt={2}>
                            <Button type="submit" variant="contained" color="primary" size="large"> {/* Change to submit button */}
                                Book Your Seat
                            </Button>
                        </Box>
                    </Grid>
                </Grid> {/* Closing Grid for the form */}
            </form>
        </Container>
    );
}

export default AssessmentSection;
