import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';

import StepByStepProcessSection from './components/StepByStepProcessSection';

import TestimonialsSection from './components/TestimonialsSection';

import Footer from './components/Footer';
import AssessmentSection from './components/Assesment';
import StudyAbroadPage from './pages/StudyAbroadPage';
import StudentEssentialsPage from './pages/StudentEssentialsPage';
import WhyPerfectGroupPage from './pages/WhyPerfectGroup';
import ContactUsPage from './pages/contact';
import './App.css'; // Import the CSS file
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Helmet } from 'react-helmet'; 
import ServiceDetailPage from './pages/ServiceDetailPage';


function App() {
  return (
      <Router>
          <Helmet>
                <title>Perfect Group Consultants</title>
                <meta name="description" content="Perfect Group Consultants is a leading consultancy firm that provides services for students looking to get student visas and immigration." />
                <meta property="og:title" content="Perfect Group Consultants" />
                <meta property="og:description" content="We help students achieve their dreams of studying abroad." />
                <meta property="og:type" content="website" />

                <link rel="icon" href="/favicon.ico" />
                <link rel="apple-touch-icon" href="/logo192.png" />
                <link rel="canonical" href="https://perfectgroup.ca/" />
               
            </Helmet>
    
          <Header />
          <Routes>
              <Route path="/" element={
                  <>

                      <HeroSection />
                      <div className="section-light-grey">
                          <AssessmentSection /> 
                      </div>
           
                          <StepByStepProcessSection />
                 
                      <div className="section-light-grey">
                          <TestimonialsSection />
                      </div>
      
           
                  </>
              } />
              <Route path="/study-abroad" element={<StudyAbroadPage />} />
              <Route path="/student-essentials" element={<StudentEssentialsPage/>} />
              <Route path="/why-us" element={<WhyPerfectGroupPage/>} />
              <Route path="/contact" element={<ContactUsPage/>} />
              <Route path="/student-essentials/:serviceTitle" element={<ServiceDetailPage />} /> {/* Dynamic route */}
              <Route path="/study-abroad/:serviceTitle" element={<ServiceDetailPage />} /> {/* Dynamic route */}
          </Routes>
          <Footer />
      </Router>
  );
}

export default App;
