const keyServices = [
    {
        title: 'Where To Study & What To Study?',
        slug: 'where-to-study-what-to-study',
        description: "/markdown/wherestudy.md",
        image: '/Rectangle25.png',
        details: "Choosing the right study destination and program is crucial to achieving your academic and career goals."
    },
    {
        title: 'Visa Application Preparation',
        slug: 'visa-application-preparation',
        description: "/markdown/visa-application-preparation.md",
         image: '/Rectangle26.png',
        details: "The visa application process can be daunting and varies significantly by country."
    },
    {
        title: 'Departure Preparation & Overseas Settlement',
        slug: 'departure-preparation-overseas-settlement',
        description: "/markdown/departure-preparation-overseas-settlement.md",
           image: '/Rectangle27.png',
        details: "Moving to a new country involves more than just securing a visa."
    },
    {
        title: 'Free Consultancy',
        slug: 'free-consultancy',
        description: "../markdown/free-consultancy.md",
         image: '/Rectangle28.png',
        details: "Start your journey with a no-obligation, free consultation with our experienced consultants."
     },
  
];




export default keyServices;