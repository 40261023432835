import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import keyServices from '../data/keyServices';
import keyEssentials from '../data/keyEssentials';

function ServiceDetailPage() {
    window.scrollTo(0, 0);
    const { serviceTitle } = useParams();
    const [markdownContent, setMarkdownContent] = useState('');
    let service = keyServices.find(service => service.slug === serviceTitle) || keyEssentials.find(service => service.slug === serviceTitle);
    const page = service ? (keyServices.includes(service) ? 'study-abroad' : 'student-essentials') : '';
    
    useEffect(() => {
        if (service?.description) {
            fetch(service.description)
                .then(response => response.ok ? response.text() : Promise.reject(response))
                .then(text => setMarkdownContent(text))
                .catch(error => console.error('Error loading markdown:', error));
        }
    }, [service]);

    if (!service) {
        return <Typography variant="h5">Service not found</Typography>;
    }

    // Define custom Markdown components
    const markdownComponents = {
        h1: ({ children }) => (
            <Typography variant="h4" component="h1" gutterBottom>{children}</Typography>
        ),
        h2: ({ children }) => (
            <Typography variant="h5" component="h2" gutterBottom>{children}</Typography>
        ),
        h3: ({ children }) => (
            <Typography variant="h6" component="h3" gutterBottom>{children}</Typography>
        ),
        p: ({ children }) => (
            <Typography variant="body1" paragraph>{children}</Typography>
        ),
        a: ({ href, children }) => (
            <Link href={href} target="_blank" rel="noopener noreferrer" underline="hover">{children}</Link>
        ),
        img: ({ src, alt }) => (
            <Box component="img" src={src} alt={alt} sx={{ width: '100%', maxHeight: '400px', mt: 2, mb: 2, objectFit: 'cover' }} />
        ),
    };

    return (
        <>
        <Helmet>
    <title>{`${service.title} - Perfect Group Consultants`}</title>
    <meta name="description" content={`Learn more about ${service.title}. Perfect Group Consultants offers ${service.title} and other services to support your international education journey.`} />
    <meta property="og:title" content={service.title} />
    <meta property="og:description" content={service.description.slice(0, 150)} />
    <link rel="canonical" href={`https://perfectgroup.ca/${page}/${service.slug}`} />
</Helmet>

        <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "Service",
                "serviceType": "${service.title}",
                "provider": {
                    "@type": "Organization",
                    "name": "Perfect Group Consultants",
                    "areaServed": "Canada, Pakistan"
                }
            }
        `}
        </script>


            <Container sx={{ my: 8 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography variant="h3" fontWeight="bold">{service.title}</Typography>
                    <img src={service.image} alt={service.title} style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', marginTop: '20px' }} />
                </Box>
                
                {/* Render the markdown content with custom components */}
                <Box sx={{ mt: 4 }}>
                    <ReactMarkdown components={markdownComponents}>{markdownContent}</ReactMarkdown>
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                    <Button component={RouterLink} to={`/${page}`} variant="outlined" sx={{ fontWeight: 'bold' }}>
                        Explore More Services
                    </Button>
                </Box>
            </Container>
        </>
    );
}

export default ServiceDetailPage;
