// StudyAbroadPage.js

import React, {useEffect} from 'react'; 
import { Container, Typography, Grid, Button, Card, CardContent, CardMedia, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Margin } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import  keyServices  from '../data/keyServices';  



function StudyAbroadPage() {
    const hash = window.location.hash;

    useEffect(() => {
        if (hash === '#faq') {
            const faqElement = document.getElementById('faq');
            if (faqElement) {
                faqElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
        else {
            window.scrollTo(0, 0);
        }
    }, [hash]);

    return (
        <>
    <Helmet>
    <title>Study Abroad with Perfect Group Consultants</title>
    <meta name="description" content="Get expert international student visa assistance from Perfect Group Consultants. We support students with visas, university applications, and more." />
     <meta property="og:title" content="Study Abroad with Perfect Group Consultants" />
    <meta property="og:description" content="Your trusted partner in planning your international education journey." />
    <meta property="og:type" content="website" />
    <link rel="canonical" href="https://perfectgroup.ca/study-abroad" />
</Helmet>


<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://perfectgroup.ca/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Study Abroad",
                "item": "https://perfectgroup.ca/study-abroad"
            }
        ]
    }
`}
</script>

<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Study Abroad Consulting",
        "provider": {
            "@type": "Organization",
            "name": "Perfect Group Consultants",
            "areaServed": "Canada, Pakistan"
        }
    }
`}
</script>
<Box 
    sx={{ 
        height: '400px', 
        backgroundImage: 'url(/studyAbroad.png)',
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',  // Center vertically
        justifyContent: 'left',  // Center horizontally
        color: 'black',
        textAlign: 'left',
        '@media (max-width:600px)': {
            backgroundImage: 'none', 
            justifyContent: 'center',
            alignItems: 'center', 
            paddingTop: '60px', 
            paddingBottom: '20px',
         
        },
    }}
>
    <Box 
        sx={{ 
            display: 'flex',  // Add this line
            flexDirection: 'column',  // Ensures items stack vertically
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            justifyContent: 'center',
            alignItems: 'left',
            padding: '20px', 
            borderRadius: '8px',
            maxWidth: '720px',
            height: "100%"
        }}
    >
<Typography variant="h2" fontWeight="bold">
    International Student Visa Assistance & University Placement
</Typography>

<Typography variant="h5" sx={{ mt: 1 }}>
    Comprehensive support for student visas, applications, and accommodation.
</Typography>

    </Box>
</Box>

            {/* Appointment Booking Section */}
            <Container sx={{ textAlign: 'center', my: 8 }}>
                <Typography variant="h4" fontWeight="bold" gutterBottom>
                    We are there to help you!
                </Typography>
                <Typography variant="body1"  sx={{ mb: 2, mt:-1  }}>
                Perfect Group Consultants provides comprehensive study abroad services including student visa support, university placement, and pre-departure assistance for international students.
            </Typography>

                <Box 
                    sx={{ width: '80px', height: '3px', backgroundColor: 'red', margin: '0 auto' }} 
                />
            
            </Container>

            {/* Key Services Section */}
            <Container sx={{ mb: 8 }}>
            <Grid container spacing={4}>
                        {keyServices.map((service, index) => (
                            <Grid item xs={12} sm={6} md={6} lg={6} key={index}> 
                                <Card elevation={3} sx={{ height: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        height="230"
                                        image={service.image}
                                        alt={service.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                                            {service.title}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary" paragraph>
                                            {service.details}
                                        </Typography>

                                   
                                           
                                    <Box sx={{ mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            component={RouterLink}
                                            to={`/study-abroad/${service.slug}`}
                                        >
                                            Learn More
                                        </Button>
                                    </Box>

                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button 
                        variant="contained" 
                        size="large" 
                        component={RouterLink}
                        to="/contact#form"
                    >
                        Contact Us for Free Consultation
                    </Button>
                </Box>
            </Container>

            {/* FAQ Section */}
            {/* FAQ Section */}
<Container maxWidth="md" sx={{ mb: 8 }} >
    <Typography id="faq" variant="h4" fontWeight="bold" align="center" gutterBottom>
        Frequently Asked Questions
    </Typography>
    <Box 
        sx={{ width: '80px', height: '3px', backgroundColor: 'red', margin: '0 auto', mb: 4 }} 
    />
    {faqs.map((faq, index) => (
        <Accordion 
            key={index} 
            sx={{ 
                mb: 2, 
                borderRadius: '10px', 
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
                overflow: 'hidden', 
                '&:before': { display: 'none' } 
            }}
        >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon />} 
                sx={{ 
                    backgroundColor: '#f5f5f5', 
                    '&:hover': { backgroundColor: '#e0e0e0' } 
                }}
            >
                <Typography variant="h6" fontWeight="medium">
                    {faq.question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fafafa', padding: '16px 24px' }}>
                <Typography variant="body1" color="text.secondary">
                    {faq.answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    ))}

<Box sx={{ display: 'flex', gap: 2, mt: 4, justifyContent: 'center', alignContent: 'center' }}>
                        
                        <Button component={RouterLink} to="/student-essentials" variant="outlined">
                            Student Essentials
                        </Button>
                        <Button component={RouterLink} to="/why-us" variant="outlined">
                           Why us
                        </Button>
                        <Button component={RouterLink} to="/contact" variant="outlined">
                            Contact Us
                        </Button>
                    </Box>
</Container>
<script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
        __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                { 
                    "@type": "Question",
                    "name": "What are the basic eligibility requirements?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Eligibility requirements typically include academic qualifications such as high school or undergraduate transcripts, English proficiency tests like IELTS/TOEFL, and entrance exams like GRE/GMAT."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "How long does the visa process take?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Visa processing can take between 4-12 weeks depending on the country and the volume of applications at the time. Some countries offer expedited services, while others might have additional interview or biometric requirements."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "Do I need to provide financial proof?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, most countries require proof that you can financially support yourself during your studies. This often includes bank statements, loan approval letters, or scholarship confirmation."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "What documents do I need?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "In general, you’ll need a valid passport, an admission letter from the university, financial proof, academic transcripts, and sometimes a health certificate or police clearance."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "Can I work while studying abroad?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, many countries allow international students to work part-time during the academic term and full-time during breaks. However, work permits and job restrictions vary by country."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "What is the cost of living in different countries?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "The cost of living depends on the country and city you choose. Cities like London and Sydney are more expensive than smaller towns. Typical expenses include rent, food, transport, and insurance."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "How do I choose the right university?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Choosing the right university involves factors like course reputation, location, job placement opportunities, campus facilities, and lifestyle. Our consultants match your goals with the best options available."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "What health insurance do I need while studying abroad?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Many countries require international students to have health insurance as part of their visa requirements. This can be a policy from the destination country or an international insurance plan."
                    }
                },
                { 
                    "@type": "Question",
                    "name": "What should I do if I face issues while studying abroad?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "If you encounter challenges, such as academic or personal issues, contact your university’s international office. In cases of legal or safety concerns, your home country’s embassy can assist."
                    }
                }
            ]
        })
    }}
></script>


        </>
    );
}

// Sample data for FAQs
const faqs = [
    {
        question: 'What are the basic eligibility requirements?',
        answer: 'Eligibility requirements typically include academic qualifications (such as high school or undergraduate transcripts), English proficiency tests like IELTS/TOEFL, and sometimes entrance exams like GRE/GMAT. The requirements vary by country and university. We can provide a personalized assessment to determine your eligibility and guide you in meeting the requirements efficiently.'
    },
    {
        question: 'How long does the visa process take?',
        answer: 'Visa processing can take anywhere between 4-12 weeks, depending on the country and the volume of applications at the time. Some countries offer expedited services, while others might have additional interview or biometric requirements. We help streamline the process by ensuring all documents are in order and your application is submitted without delay.'
    },
    {
        question: 'Do I need to provide financial proof?',
        answer: 'Yes, most countries require proof that you can financially support yourself during your studies. This often includes bank statements showing sufficient funds, loan approval letters, or scholarship confirmation. We’ll guide you through the exact requirements for your destination and help prepare the documentation to avoid common pitfalls.'
    },
    {
        question: 'What documents do I need?',
        answer: 'In general, you’ll need a valid passport, an admission letter from the university, financial proof, academic transcripts, and sometimes a health certificate or police clearance. Our team provides a tailored checklist for your specific destination to ensure nothing is overlooked.'
    },
    {
        question: 'Can I work while studying abroad?',
        answer: 'Yes, many countries allow international students to work part-time (e.g., 20 hours per week) during the academic term and full-time during breaks. However, work permits and job restrictions vary by country. We’ll provide guidance on the specific rules for your destination and help you explore job opportunities if needed.'
    },
    {
        question: 'What is the cost of living in different countries?',
        answer: 'The cost of living depends on the country and city you choose. For example, cities like London and Sydney are more expensive than smaller towns in the UK or Australia. Typical expenses include rent, food, transport, and insurance. We’ll assist you in preparing a detailed budget and offer tips to manage your finances effectively.'
    },
    {
        question: 'How do I choose the right university?',
        answer: 'Choosing the right university involves considering factors like course reputation, location, job placement opportunities, campus facilities, and lifestyle. Our consultants use a personalized approach to match your goals and preferences with the best options available, ensuring you make an informed decision.'
    },
    {
        question: 'What health insurance do I need while studying abroad?',
        answer: 'Many countries require international students to have health insurance as part of their visa requirements. This can either be a policy from the destination country or an international insurance plan. We’ll help you understand your options and select a policy that meets both visa requirements and your personal needs.'
    },
    {
        question: 'What should I do if I face issues while studying abroad?',
        answer: 'If you encounter challenges, such as academic difficulties or personal issues, you should contact your university’s international office for support. In cases of legal or safety concerns, your home country’s embassy can assist. We also stay connected with our clients throughout their study journey to provide ongoing support and guidance.'
    },
];

export default StudyAbroadPage;
 