// StudentEssentialsPage.js

import React, {useEffect} from 'react';
import { 
    Container, Typography, Grid, Card, CardContent, CardMedia, Box, Stack , Button 
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import keyEssentials from '../data/keyEssentials';


function StudentEssentialsPage() {
    const hash = window.location.hash;

    useEffect(() => {
        if (hash === '#faq') {
            const faqElement = document.getElementById('faq');
            if (faqElement) {
                faqElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
        else {
            window.scrollTo(0, 0);
        }
    }, [hash]);

    return (
        <>
     <Helmet>
    <title>Student Essentials for International Students - Perfect Group Consultants</title>
    <meta name="description" content="Find essential services for international students including loans, affordable accommodation, and reliable money transfer options." />
    <meta property="og:title" content="Student Essentials for Study Abroad" />
    <meta property="og:description" content="Discover services for students studying abroad, from banking to housing solutions." />
    <meta property="og:type" content="website" />
    <link rel="canonical" href="https://perfectgroup.ca/student-essentials" />
</Helmet>


<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://perfectgroup.ca/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Student Essentials",
                "item": "https://perfectgroup.ca/student-essentials"
            }
        ]
    }
`}
</script>

<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Student Essentials",
        "provider": {
            "@type": "Organization",
            "name": "Perfect Group Consultants",
            "areaServed": "Canada, Pakistan"
        }
    }
`}
</script>

<Box 
    sx={{ 
        height: '400px', 
        backgroundImage: 'url(/banner-image2.png)',
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',  // Center vertically
        justifyContent: 'left',  // Center horizontally
        color: 'black',
        textAlign: 'left',
        '@media (max-width:600px)': {
            backgroundImage: 'none', 
            justifyContent: 'center',
            alignItems: 'center', 
           
        },
    }}
>
    <Box 
        sx={{ 
            display: 'flex',  // Add this line
            flexDirection: 'column',  // Ensures items stack vertically
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            justifyContent: 'center',
            alignItems: 'left',
            padding: '20px', 
            borderRadius: '8px',
            maxWidth: '720px',
            height: "100%"
        }}
    >
   <Typography variant="h2" fontWeight="bold">
                Essential Services for International Students
            </Typography>
            <Typography variant="h5">
                Loans, Accommodation, and Money Transfers for Students Abroad
            </Typography>
    </Box>
</Box>

            {/* Introduction Section */}
            <Container sx={{ my: 8, textAlign: 'left' }}>
                <Typography variant="h5" paragraph>
                    Get access to the essentials you need to succeed abroad.
                </Typography> 
                <Typography variant="body1">
                    Our student essentials cover everything from international student loans to affordable accommodation and money transfers. Access the resources you need for a smooth transition abroad.
                </Typography>

            </Container>

            {/* Services Section */}
            <Container sx={{ mb: 8 }}>
                <Grid container spacing={4}>
                    {keyEssentials.map((service, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardMedia
                                    component="img"
                                    height="240"
                                    image={service.image}
                                    alt={service.title}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                                        <img 
                                            src={service.icon} 
                                            alt={`${service.title} icon`} 
                                            style={{ width: 40, height: 40 }}
                                        />
                                        <Typography variant="h6" fontWeight="bold">
                                            {service.title}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="body2" color="text.secondary">
                                        {service.details}
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            component={RouterLink}
                                            to={`/student-essentials/${service.slug}`}
                                        >
                                            Learn More
                                        </Button>
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button 
                        variant="contained" 
                        size="large" 
                        component={RouterLink}
                        to="/contact#form"
                    >
                        Contact Us
                    </Button>
                </Box>
            </Container>
            

        </>
    );
}

// Sample data for services


export default StudentEssentialsPage;