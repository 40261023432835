import React from 'react';
import { Container, Box, Typography, Grid, Paper, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function TestimonialsSection() {
    const testimonials = [
        { name: "Mukesh Kumar, Canada", video: "https://www.example.com/video1.mp4", path: "/Rectangle14.png" },
        { name: "John Doe, USA", video: "https://www.example.com/video2.mp4", path: "/Rectangle16.png" },
        { name: "Jane Smith, UK", video: "https://www.example.com/video3.mp4", path: "/Rectangle18.png" },
        { name: "Emily Johnson, Australia", video: "https://www.example.com/video4.mp4",path: "/Rectangle20.png"},
    ];

    return (
        <Container style={{ marginTop: '100px', marginBottom: '0px', padding: "100px" }}>
            <Box textAlign="center" marginBottom={2}>
                <Typography variant="h4" gutterBottom>
                    Student Testimonials
                </Typography>
                <Box 
                    sx={{ width: '80px', height: '3px', backgroundColor: 'red', margin: '0 auto' }} 
                />
            </Box>
            <Grid container spacing={4}>
                {testimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                            <Box
                                component="img"
                                src={testimonial.path}
                                style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                            />
                            <Typography variant="body1" marginTop={1}>
                                {testimonial.name}
                            </Typography>
                           
                        </Paper>
                    </Grid>
                ))}
            </Grid>
           
        </Container>
    );
}

export default TestimonialsSection;
