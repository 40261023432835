// Header.js

import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Grid, IconButton, Container, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="inherit" elevation={0} sx={{ borderBottom: '1px solid #e0e0e0', marginBottom:"10px"}}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {/* Logo Section */}
                        <Grid item>
                            <img src='/logo.png' alt='Company Logo' width='auto' height='50' style={{ marginLeft: { xs: 100, md: "-100px" } }} />
                        </Grid>

                        {/* Navigation Links */}
                        <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {[
                                { text: 'Home', path: '/' },
                                { text: 'Study Abroad', path: '/study-abroad' },
                                { text: 'Student Essentials', path: '/student-essentials' },
                                { text: 'Why Perfect Group ', path: '/why-us' },
                                { text: 'Contact Us', path: '/contact' }
                            ].map(({ text, path }) => (
                                <Button 
                                    key={text} 
                                    color="inherit" 
                                    sx={{ 
                                        marginLeft: 2, 
                                        position: 'relative',
                                        '&:hover::after': {
                                            content: '""',
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            height: '2px',
                                            backgroundColor: 'red',
                                        }
                                    }} 
                                    component={RouterLink} 
                                    to={path}
                                >
                                    {text}
                                </Button>
                            ))}
                        </Grid>

                        {/* Mobile Menu Icon */}
                        <Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {[
                                    { text: 'Home', path: '/' },
                                    { text: 'Study Abroad', path: '/study-abroad' },
                                    { text: 'Student Essentials', path: '/student-essentials' },
                                    { text: 'Why Perfect Group Consulting ', path: '/why-us' },
                                    { text: 'Contact Us', path: '/contact' }
                                ].map(({ text, path }) => (
                                    <MenuItem key={text} onClick={handleMenuClose} component={RouterLink} to={path}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
